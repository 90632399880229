<template>
  <div class="header-actions">
    <button class="header-call button"
            @click="$root.$refs.modal.show(form)">
      Заказать звонок
    </button>

    <div class="header-contacts">
      <a class="header-phone" href="tel:88127024173">8 (812) 702-41-73</a>
      <div class="header-address">
        <img src="../../assets/images/icons/address.png" alt>
        <p>Санкт-Петербург, Савушкина 119, к.4</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderActions",

  data() {
    return {
      form: {
        subject: 'Кнопка Заказать звонок в шапке',
        title: 'Заказать звонок',
        order_id: 1,
      }
    }
  }
}
</script>

<style scoped>

</style>
